<template>
  <div v-if="user">
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AccountLayout',
  components: {},
  computed: {
    ...mapState('account', ['user'])
  },
  mounted() {
    if (!this.user) {
      this.$store.dispatch('account/getUser')
    }
  }
}
</script>