<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <div>
        <div class="text-uppercase font-weight-bold body-2 primary--text mb-2">Account</div>
        <h2 class="text-h3 text-lg-h2">Profile page</h2>
      </div>
      <div class="mt-6">
        <div class="mt-4">
          <v-card class="py-4 px-2" color="surface">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet
                  color="blue-grey"
                  rounded
                  class="pa-2"
                  style="margin-top: 3px"
                  dark
                >
                  <v-icon>mdi-account</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5 font-weight-bold">User Details</div>
                <div class="font-weight-regular mt-1">
                  <table>
                    <tbody>
                      <tr><td width="100"><strong>ID</strong></td><td>{{ user.id }}</td></tr>
                      <tr><td><strong>Username</strong></td><td>{{ user.username }}</td></tr>
                      <tr><td><strong>Firstname</strong></td><td>{{ user.firstname }}</td></tr>
                      <tr><td><strong>Lastname</strong></td><td>{{ user.lastname }}</td></tr>
                      <tr><td><strong>Email</strong></td><td>{{ user.email }}</td></tr>
                      <tr><td><strong>Last Login</strong></td><td>{{ user.last_login | formatDate('hh:mm b, MMM dd, yyyy') }}</td></tr>
                      <tr><td><strong>Superuser</strong></td><td>{{ user.is_superuser }}</td></tr>
                    </tbody>
                  </table>
                </div>
                <div class="text-h5 font-weight-bold">User Groups</div>
                <div class="font-weight-regular mt-1">
                  {{ user.groups }}
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProfilePage',
  components: {},
  computed: {
    ...mapState('account', ['user', 'groups', 'isSuperUser'])
  },
  mounted() {
    if (!this.user) {
      this.$store.dispatch('account/getUser')
    }
  }
}
</script>